import animationImage from '../../assets/Animations.png';
import adventureGameImage from '../../assets/AdventureGame.png';
import diceImage from '../../assets/DiceRollers.png';

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ProjectLink from '../ProjectLink';

export default function ProjectsPage() {
    return (
        <>
            <div className="image-track" >
                <Link to='/projects/animations' className="page-header-item-link" >
                    <ProjectLink image={animationImage} title="See My Animations" label="A still picture of circles rotating in a pattern" />
                </Link>
                <Link to='/projects/adventureGame' className="page-header-item-link" >
                    <ProjectLink image={adventureGameImage} title="Adventure Game" label="A picture of a Zork like text based adventure game" />
                </Link>
                <Link to='/projects/newdice' className="page-header-item-link" >
                    <ProjectLink image={diceImage} title="New Dice" label="Not much, at first." />
                </Link>
                <Link to='/projects/sentinelsTracker' className="page-header-item-link" >
                    <ProjectLink image={diceImage} title="Sentinels Tracker" label="Not much, at first." />
                </Link>
                <Link to='/projects/photoWall' className="page-header-item-link" >
                    <ProjectLink image={diceImage} title="Photo Wall" label="Not much, at first." />
                </Link>
            </div>
        </>
    )
  }