import alexImg from '../../assets/Alex.jpg';

export default function HomePage() {

  return (
      <div>
        <h1 className='home-title-1'>ALEX</h1>
        <h1 className='home-title-2'>BAMBERGER</h1>
        <p className='home-text'>Seeking entry level position as a front end web developer allowing me to utilize my education while gaining work experience in a professional environment.</p>
        <p>
            <img src={alexImg} alt='Alex Bamberger' className='home-image' />
        </p>
      </div>
  )
}