import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import './NewDice/NewDice.css';

import PageHeader from './Components/PageHeader';
import AboutPage from './Components/Pages/AboutPage';
import HomePage from './Components/Pages/HomePage';
import ProjectsPage from './Components/Pages/ProjectsPage';
import AnimationsPage from './Components/Pages/AnimationsPage';
import AdventureGame from './AdventureGame/AdventureGame';
import ResumePage from './Components/Pages/ResumePage';
import NewDice from './NewDice/NewDice';

function App() {
  return (
    <Router>
      <div className="App">
      <PageHeader />
      <Switch>
        <Route exact path="/"><HomePage /></Route>
        <Route exact path="/about"><AboutPage /></Route>
        <Route exact path="/resume"><ResumePage /></Route>
        <Route exact path="/projects"><ProjectsPage /></Route>

        <Route exact path="/projects/animations"><AnimationsPage /></Route>
        <Route exact path="/projects/adventureGame"><AdventureGame /></Route>
        <Route exact path="/projects/newDice"><NewDice /></Route>
      </Switch>
    </div>
    </Router>
  )
}

export default App;
