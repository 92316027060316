export default function Inventory({inventory}) {
    return (
        <div id="inventory">
            <h1>Inventory</h1>
            <div id="inventoryItems">
                {inventory.map(item => (
                    <div key={item.name}>{item.name}: {item.description}</div>
                ))}
            </div>
        </div>
    )
}