import Die from "./Die"

export default function DiceSetPreview({ diceSet, onChangeSet }) {
    return (
        <div 
            className="dice-set-preview" 
            onClick={onChangeSet}
            style={{ cursor: 'pointer' }}
            title="Click to change dice set"
        >
            <h2 className="dice-set-preview-title">{diceSet.name}</h2>
            <div className="dice-set-preview-dice">
                {diceSet.dice.map((die) => (
                    <Die 
                        key={die.index} 
                        index={-1} 
                        color={die.color} 
                        numSides={die.numSides} 
                        size="small" 
                    />
                ))}
            </div>
        </div>
    );
} 