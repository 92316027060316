import Animate from "../../Animations/Animate";

export default function AnimationsPage() {
  return (
      <div>
        <p>
            <Animate />
        </p>
      </div>
  )
}