import { useState } from "react";
import Die from "./Die";
import DiceColorPicker from "./DiceColorPicker";

export default function DieEditor({dieOptions, handleEditDie, handleRemoveDie, selectedDieIndex, title, selectedDiceSet, onClose}) {
    let [selectedDieType, setSelectedDieType] = useState(null);
    
    // Get the current die type - either the selected one or the one being edited
    const currentDieType = selectedDieType || (selectedDieIndex !== undefined ? selectedDiceSet.dice[selectedDieIndex] : null);
    
    // Initialize color state with the current die's color if editing, otherwise white
    let [selectedColor, setSelectedColor] = useState(currentDieType ? currentDieType.color : "#FFFFFF");

    function handleSelectColor(color) {
        setSelectedColor(color);
    }

    function handleSelectDieType(die) {
        setSelectedDieType(die);
    }

    function handleAddDie() {
        if (selectedDieType) {
            // Create a new die object with the selected type and color
            const newDie = {
                index: selectedDieIndex !== undefined ? selectedDieIndex : selectedDiceSet.dice.length,
                color: selectedColor,
                numSides: selectedDieType.numSides,
                value: 0,
                modifier: 0
            };
            handleEditDie(newDie, selectedColor, selectedDieIndex);
            setSelectedDieType(null);
            onClose();
        } else if (selectedDieIndex !== undefined) {
            // If we're just changing the color, create a new die with the same type but new color
            const newDie = {
                index: selectedDieIndex,
                color: selectedColor,
                numSides: currentDieType.numSides,
                value: currentDieType.value,
                modifier: currentDieType.modifier
            };
            handleEditDie(newDie, selectedColor, selectedDieIndex);
            onClose();
        }
    }

    function getContrastingBackground(color) {
        // Convert hex to RGB
        const hex = color.replace('#', '');
        const r = parseInt(hex.substr(0, 2), 16);
        const g = parseInt(hex.substr(2, 2), 16);
        const b = parseInt(hex.substr(4, 2), 16);

        // Calculate relative luminance
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        // For light colors, use a slightly darker background
        if (luminance > 0.5) {
            return '#e9ecef'; // Light gray
        } else {
            // For dark colors, use a slightly lighter background
            return '#f8f9fa'; // Very light gray
        }
    }

    return (
        <div className="dice-set-editor-right">
            {(selectedDieIndex !== undefined || selectedDieType) && (
                <div className="dice-set-editor-section">
                    <h3 className="dice-set-editor-section-title">{title}</h3>
                    <div 
                        className="dice-set-editor-preview"
                        style={{ backgroundColor: getContrastingBackground(selectedColor) }}
                    >
                        <Die 
                            index={-1}
                            color={selectedColor}
                            numSides={currentDieType?.numSides || 6}
                            value={currentDieType?.index || 0}
                            className={selectedDieIndex !== undefined ? 'editing' : ''}
                        />
                    </div>
                </div>
            )}

            <div className="dice-set-editor-section">
                <h3 className="dice-set-editor-section-title">Select Die Type</h3>
                <div 
                    className="dice-set-editor-options"
                    style={{ backgroundColor: getContrastingBackground(selectedColor) }}
                >
                    {dieOptions.dice.map((die) => (
                        <Die 
                            key={die.index} 
                            index={-1} 
                            color={selectedColor} 
                            numSides={die.numSides} 
                            onClick={() => handleSelectDieType(die)}
                            className={selectedDieType?.numSides === die.numSides ? 'selected' : ''}
                        />
                    ))}
                </div>
            </div>

            <div className="dice-set-editor-section">
                <h3 className="dice-set-editor-section-title">Choose Color</h3>
                <DiceColorPicker selectedColor={selectedColor} handleSelectColor={handleSelectColor}/>
            </div>

            <div className="dice-set-editor-buttons">
                {(selectedDieType || (currentDieType && selectedColor !== currentDieType.color)) && (
                    <button 
                        className="dice-set-editor-add-button"
                        onClick={handleAddDie}
                    >
                        {selectedDieIndex !== undefined ? "Update Die" : "Add Die"}
                    </button>
                )}

                {selectedDieIndex !== undefined && (
                    <button 
                        className="dice-set-editor-delete-button"
                        onClick={() => handleRemoveDie(selectedDieIndex)}
                    >
                        Delete Die
                    </button>
                )}

                <button 
                    className="dice-set-editor-cancel-button"
                    onClick={onClose}
                >
                    Cancel
                </button>
            </div>
        </div>
    )
}