import Image from "./Image";
import { motion } from "framer-motion";

export default function ProjectLink({title, image, label}) {
    return (
        <motion.div 
            className="project-link-container"
            whileHover={{ scale: 1.1, backgroundColor: 'var(--select-color)' }}
            transition={{ type: 'spring', stiffness: 500 }}
        >
            <p class="project-link-title">{title}</p>
            <Image img={image} label={label} />
        </motion.div>
    )
}