import Die from "./Die"

export default function DisplayDiceSet({diceSet, selectedDiceSet, handleSelectDiceSet}) {

    let background = "var(--menu-color)";
    if (selectedDiceSet === diceSet) { background = "lightcoral" }

    const isSelected = selectedDiceSet === diceSet;
    const className = `dice-holder ${isSelected ? 'selected' : ''}`;

    return (
        <div className={className} style={{backgroundColor:background}}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <div onClick={() => handleSelectDiceSet(diceSet)} style={{flex: 1}}>
                    <p className="dice-holder-title">{diceSet.name}</p>
                    <div>
                        {diceSet.dice.map((die) => {
                            return(
                                <Die 
                                    key={die.index} 
                                    index={-1} 
                                    color={die.color} 
                                    numSides={die.numSides} 
                                    size="small" 
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}