import { useRef, useEffect, useState } from "react";
import { pi, cos, sin, sqrt } from 'mathjs';
import {createNoise2D} from './simplex-noise';

export default function RotatingPies() {
    const [frame, setFrame] = useState(1);
    const canvas = useRef();
    const [isPaused, setIsPaused] = useState(true);
    const [isReversed, setIsReversed] = useState(false);
    const [doLinger, setDoLinger] = useState(false);
    const [delay, setDelay] = useState(50);
    const [size, setSize] = useState(5);
    const [grid, setGrid] = useState(50);
    const [canvasWidth, setCanvasWidth] = useState(500);
    const [canvasHeight, setCanvasHeight] = useState(500);
    const [step, setStep] = useState(1);
    const [frameCount, setFrameCount] = useState(60);
    const [color1, setColor1] = useState();
    const [color2, setColor2] = useState();
    const [color3, setColor3] = useState();


    function handlePause() {setIsPaused(!isPaused);}
    function handleReverse() {setIsReversed(!isReversed);}
    function handleLinger() {setDoLinger(!doLinger);}
    const handleDelay = event => {setDelay(event.target.value);};
    const handleSize = event => {setSize(event.target.value);};
    const handleGrid = event => {setGrid(event.target.value);};
    const handleWidth = event => {setCanvasWidth(event.target.value);};
    const handleHeight = event => {setCanvasHeight(event.target.value);};
    const doSetStep = event => {setStep(event.target.value);};
    const handleFrames = event => {setFrameCount(event.target.value);};
    const handleColor1 = event => {setColor1(event.target.value);};
    const handleColor2 = event => {setColor2(event.target.value);};
    const handleColor3 = event => {setColor3(event.target.value);};

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isPaused) {
                if (!isReversed) {
                    if (frame < 60) {
                        setFrame(frame + 1);
                    } else {
                        setFrame(1);
                    }
                } else {
                    if (frame > 1) {
                        setFrame(frame - 1);
                    } else {
                        setFrame(60);
                    }
                }
            }
        }, delay);
    
        return () => {
          clearInterval(interval);
        };
      });

    useEffect(() => {
        let noise2D = createNoise2D();
        if (canvas.current) {
            const ctx = canvas.current.getContext('2d');
            let fraction = frame / frameCount;

            if (!doLinger) {
                ctx.clearRect(0, 0, canvasWidth, canvasHeight);
            }

            for(let i = 0; i < grid; i++)
                {
                    let p = 1.0 * (i + fraction) / grid;
            
                    // Random Thirds of Pie
                    let v1 = ((noise2D(120 * p, 100) + 1) / 2.0) ** 2.0;
                    let v2 = ((noise2D(120 * p, 200) + 1) / 2.0) ** 2.0;
                    let v3 = ((noise2D(120 * p, 300) + 1) / 2.0) ** 2.0;
                    let sumv = v1 + v2 + v3;
            
                    let alpha = 20 * Math.PI * p;
            
                    // Angles
                    let theta0 = alpha + 0;
                    let theta1 = alpha + (v1 / sumv) * (2 * Math.PI);
                    let theta2 = alpha + ((v1 + v2) / sumv) * (2 * Math.PI);
                    let theta3 = alpha + (2 * Math.PI);
            
                    let r = 30 * Math.sin(Math.PI * p);
            
                    // Location
                    let q = Math.sqrt(p);
                    let theta = 7 * 2 * Math.PI * q;
                    let r2 = 0.4 * canvasWidth * q;
                    let posX = r2 * Math.cos(theta) + (canvasWidth / 2);
                    let posY = r2 * Math.sin(theta) + (canvasHeight / 2);
            
                    // Draw Slices
                    ctx.fillStyle = color1;
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(posX, posY);
                    ctx.arc(posX, posY, Math.abs(r / 2), -theta0, -theta1, true);
                    ctx.lineTo(posX, posY);
                    ctx.closePath();
                    ctx.stroke();
                    ctx.fill();
                    ctx.restore();
            
                    ctx.fillStyle = color2;
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(posX, posY);
                    ctx.arc(posX, posY, Math.abs(r / 2), -theta1, -theta2, true);
                    ctx.lineTo(posX, posY);
                    ctx.closePath();
                    ctx.stroke();
                    ctx.fill();
                    ctx.restore();
            
                    ctx.fillStyle = color3;
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(posX, posY);
                    ctx.arc(posX, posY, Math.abs(r / 2), -theta2, -theta3, true);
                    ctx.lineTo(posX, posY);
                    ctx.closePath();
                    ctx.stroke();
                    ctx.fill();
                    ctx.restore();
                }
        }
    }, [frame, canvasHeight, canvasWidth, color1, color2, color3, doLinger, frameCount, grid]);


    function handleStep() {
        if (!isReversed) {
            if (frame < 60) {
                setFrame(Number(frame) + Number(step));
            } else {
                setFrame(1);
            }
        } else {
            if (frame > 1) {
                setFrame(Number(frame) - Number(step));
            } else {
                setFrame(60);
            }
        }
    }

    return (
        <>
            <div>
                <button onClick={handlePause}>{isPaused ? 'Play Animation' : 'Pause Animation'}</button>
                <button onClick={handleReverse}>{isReversed ? 'Playing Reversed' : 'Playing Forward'}</button>
                <button onClick={handleLinger}>{doLinger ? 'Frames Linger' : 'Frames Erased'}</button>
                <button onClick={handleStep}>Step Animation</button>
            </div>
            <div>
                <p className="animationRange">Color 1: {color1}</p>
                <input onChange={handleColor1} type='color' value='#cceabb' />
                <p className="animationRange">Color 2: {color2}</p>
                <input onChange={handleColor2} type='color' value='#fdcb9e' />
            </div>
            <div>
                <p className="animationRange">Color 3: {color3}</p>
                <input onChange={handleColor3} type='color' value='#3f3f44' />
                <p className="animationRange">Dot Size: {size}</p>
                <input onChange={handleSize} type='range' min='1' max='20' step='1' value={size} />
            </div>
            <div>
                <p className="animationRange">Step: {step}</p>
                <input onChange={doSetStep} type='range' min='1' max='5' step='1' value={step} />
                <p className="animationRange">Delay: {delay}</p>
                <input onChange={handleDelay} type='range' min='1' max='60' step='1' value={delay} />
            </div>
            <div>
                <p className="animationRange">Canvas Height: {canvasHeight}</p>
                <input onChange={handleHeight} type='range' min='100' max='500' step='20' value={canvasHeight} />
                <p className="animationRange">Canvas Width: {canvasWidth}</p>
                <input onChange={handleWidth} type='range' min='100' max='500' step='20' value={canvasWidth} />
            </div>
            <div>
                <p className="animationRange">Total Frames: {frameCount}</p>
                <input onChange={handleFrames} type='range' min='10' max='200' step='1' value={frameCount} />
                <p className="animationRange">Grid Size: {grid}</p>
                <input onChange={handleGrid} type='range' min='10' max='100' step='1' value={grid} />
            </div>
            <canvas
                width={canvasWidth}
                height={canvasHeight}
                className="canvas"
                ref={canvas}
            >
            </canvas>
            <p>Based on the tutorial by bleuje: https://bleuje.com/tutorial1/</p>
        </>
    )
}