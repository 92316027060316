import { motion } from "framer-motion"
import DisplayDiceSet from "./DisplayDiceSet"

export default function DiceSelector({ diceSets, selectedDiceSet, handleSelectDiceSet, handleRemoveDiceSet, makeNewDiceSet, handleEditDiceSet }) {

    return (
        <motion.div 
            className={"dice-window"}
            initial={{ opacity: 0, y: 1000 }}
            animate={{ opacity: 1, y: 0, delay: 0.25 }}
            exit={{ opacity: 0, y: 1000, delay: 0.25 }}
            transition={{ duration: 1 }}
        >
            {diceSets.map((diceSet) => {
                return (
                    <div key={diceSet.index} style={{display: "flex", alignItems: "center", gap: "10px"}}>
                        <DisplayDiceSet 
                            diceSet={diceSet} 
                            selectedDiceSet={selectedDiceSet} 
                            handleSelectDiceSet={handleSelectDiceSet}
                        />
                        <div style={{display: "flex", gap: "10px"}}>
                            {diceSets.length > 1 && 
                                <button 
                                    className="dice-action-button delete"
                                    onClick={() => handleRemoveDiceSet(diceSet.index)}
                                    title="Delete dice set"
                                >
                                    <svg viewBox="0 0 24 24">
                                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
                                    </svg>
                                </button>
                            }
                            <button 
                                className="dice-action-button edit"
                                onClick={() => handleEditDiceSet(diceSet)}
                                title="Edit dice set"
                            >
                                <svg viewBox="0 0 24 24">
                                    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.05c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                )
            })}
            <button 
                className="add-dice-set-button"
                onClick={makeNewDiceSet}
            >
                Add Dice Set
            </button>
        </motion.div>
    )
}