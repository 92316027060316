import DesyncBalls from "./DesyncBalls";
import SpinningBall from "./SpinningBall";
import Squares from "./Squares";
import RotatingSquares from "./RotatingSquares";
import Ripples from "./Ripples";
import Noise from "./Noise";
import RotatingPies from "./RotatingPies";
import { useState } from "react";

export default function Animate() {
    const [currentAnimation, setCurrentAnimation] = useState('ChooseAnimation');

    const handleChangeAnimation = event => {setCurrentAnimation(event.target.value);};

    return (
        <>
            <select onChange={handleChangeAnimation} value={currentAnimation} >
                <option value='ChooseAnimation' >Choose an Animation</option>
                <option value='DesyncBalls' >Desync Balls</option>
                <option value='SpinningBall' >Spinning Ball</option>
                <option value='Squares' >Squares</option>
                <option value='RotatingSquares' >Rotating Squares</option>
                <option value='Ripples' >Ripples</option>
                <option value='Noise' >Noise</option>
                <option value='RotatingPies' >Rotating Pies</option>
            </select>
            <div>
                {currentAnimation === 'DesyncBalls' && <DesyncBalls />}
                {currentAnimation === 'SpinningBall' && <SpinningBall />}
                {currentAnimation === 'Squares' && <Squares />}
                {currentAnimation === 'RotatingSquares' && <RotatingSquares />}
                {currentAnimation === 'Ripples' && <Ripples />}
                {currentAnimation === 'Noise' && <Noise />}
                {currentAnimation === 'RotatingPies' && <RotatingPies />}
            </div>
        </>
    )
}