import HomeHeaderItem from "./HomeHeaderItem";
import PageHeaderItem from "./PageHeaderItem";
import { Link } from "react-router-dom";

export default function PageHeader() {
    return (
        <header className="page-header">
            <HomeHeaderItem text="alexbamberger.com" link='/' />
            <PageHeaderItem text="About" link='/about' />
            <PageHeaderItem text="Projects" link='/projects' />
            <PageHeaderItem text="Resume" link='/resume' />
        </header>
    )
}