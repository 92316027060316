import Console from "./Console";
import Inventory from "./Inventory";
import World from "./World";
import { useEffect, useState } from "react"

export default function AdventureGame() {
    const [logText, setLogText] = useState('You wake up in an old Library.');
    const [lastDir, setLastDir] = useState('north');

    const [rooms, setRooms] = useState([
        {
            name: "Start Game",
            description: `Type 'Start' to begin.`,
            itemDescription: "",
            items: [],
            isDark: false,
            north: "",
            south: "",
            east: "",
            west: ""
        },
        {
            name: "Library",
            description: `You are in a vast and sprawling library. 
                Shelves climb over your head until they reach an arched wooden roof above. 
                A variety of books and scrolls are scattered throughout the shelves, leaning and piled on top of each other. 
                The books leave a faint musty smell in the air around you.`,
            itemDescription: "In the corner of the room there is a small table, strewn atop it's surface is a",
            items: [
                {
                    name:"Book", 
                    description:"A Field Guide to the Birds of New Halstenia, written by Odrik Kor.",
                    use:activateBook,
                },
                {
                    name:"Lantern", 
                    description:"A small, sturdy hooded iron lantern. 'N.H. Ironworks'. The hood is currently lowered.", 
                    use:activateLantern, 
                    illuminate:false
                },
            ],
            isDark: false,
            north: "Cellar",
            south: "Shrine",
            east: "",
            west: ""
        },
        {
            name: "Cellar",
            description: `A small damp, triangular cellar made of sturdy blocks of stone. 
                A thick moss winds its way through the cracks between the stone bricks that make up the walls. 
                Water drips from the ceiling above, creating a steady sound as it hits an overturned bucket below. 
                An assortment of tools lie on a set of racks on the side wall with a group of barrels and tankards stacked against the back wall.`,
            itemDescription: "Among the racks of tools on the side wall you find a",
            items: [
                {
                    name:"Knife", 
                    description:"A rusting kitchen knife, currently useless.",
                    use:() => logAction("You can't use the knife."),
                },
            ],
            isDark: true,
            north: "Castle",
            south: "Library",
            east: "",
            west: ""
        },
        {
            name: "Castle",
            description: `The castle around you is tall and imposing, stone bricks more than a meter wide make up grand towers and thick walls all around you. 
                A wooden walkway rings the inner wall above you. Slivers of light come through the gaps between buildings and walls, allowing just a touch of the sun.`,
            itemDescription: "In one of the castle's walls you find a small cavity, within is a",
            items: [
                {
                    name:"Disk", 
                    description:"An intricately carved emerald disk nearly 6 inches in diameter.",
                    use:() => activateCoin("Disk"),
                },
            ],
            isDark: false,
            north: "Courtyard",
            south: "Cellar",
            east: "",
            west: ""
        },
        {
            name: "Courtyard",
            description: `The central courtyard of the castle is wide open, seeming empty in comparison to the cramped corridors and alleys throughout most of the castle. 
                An old, gnarled tree stands slumping in the center of the courtyard, birdsong coming from within its branches. 
                Next to the wall there are a few scattered stalls and crates, empty and abandoned.`,
            itemDescription: "Resting on a largely intact cart adjacent to the stalls is a",
            items: [
                {
                    name:"Parrot", 
                    description:"A blue winged Ixian secret keeper. It holds a blue coin in its beak.",
                    use:activateParrot,
                    cantPickup:cantPickupParrot,
                },
            ],
            isDark: false,
            north: "",
            south: "Castle",
            east: "",
            west: ""
        },
        {
            name: "Shrine",
            description: `A round stone room with an arched roof. 
                Golden inlays run up the wall and meet in the center of the ceiling, forming a circular skylight. 
                At the center of the room is a gilded, triangular shrine. On each side is a recessed circular slot. 
                There is a large door to the south, sealed shut.`,
            itemDescription: "Scattered across the rough stone floor is a",
            items: [
                {
                    name:"Square", 
                    description:"An intricately carved ruby square nearly 3 inches to a side.",
                    use:() => activateCoin("Square"),
                },
            ],
            isDark: false,
            lock: 0,
            north: "Library",
            south: "",
            east: "",
            west: ""
        },
        {
            name: "Treasury",
            description: `A massive vault filled with gold and gems of every kind.`,
            itemDescription: "Among the piles of gold and jewels is a",
            items: [],
            isDark: false,
            north: "Shrine",
            south: "",
            east: "",
            west: ""
        }
    ]);

    const [currentRoom, setCurrentRoom] = useState(
        {
            name: "Start Game",
            description: `Type 'Start' to begin.`,
            itemDescription: "",
            items: [],
            isDark: false,
            north: "",
            south: "",
            east: "",
            west: ""
        });
    const [inventory, setInventory] = useState([]);

    function act(action, detail) {
        switch (action) {
            case "move":
                handleMove(currentRoom[detail], detail);
                break;
            case "pickup":
                handlePickupItem(detail);
                break;
            case "drop":
                handleDropItem(detail);
                break;
            case "use":
                handleUseItem(detail);
                break;
            case "start":
                updateRoom(rooms[1]);
                break;
            default:
                logAction('test');
        }
    }

    function handleMove(destination, direction) {
        if (destination !== "") {
            let roomToGo = rooms.filter(room => room.name === destination);
            updateRoom(roomToGo[0]);
            logAction(`You move ${direction} to the ${destination}.`);
            setLastDir(direction);
        } else {
            logAction(`You can't move ${direction} from the ${currentRoom.name}.`);
        }
    }

    function handlePickupItem(pickupItem) {
        let itemToPickup = currentRoom.items.filter(item => item.name.toLowerCase() === pickupItem.toLowerCase());
        if (itemToPickup.length !== 0) {
            if (!itemToPickup[0].cantPickup) {
                inventory.push(itemToPickup[0]);
                let indexItem = currentRoom.items.indexOf(itemToPickup[0]);
                currentRoom.items.splice(indexItem, 1);
                logAction(`You pickup the ${pickupItem}.`);
            } else {
                itemToPickup[0].cantPickup();
            }
        } else {
            logAction(`There isn't a ${pickupItem} in this room.`);
        }
    }

    function handleDropItem(dropItem) {
        let itemToDrop = inventory.filter(item => item.name.toLowerCase() === dropItem.toLowerCase());
        if (itemToDrop.length !== 0) {
            currentRoom.items.push(itemToDrop[0]);
            let indexItem = inventory.indexOf(itemToDrop[0]);
            inventory.splice(indexItem, 1);
            logAction(`You drop the ${dropItem}.`);
        } else {
            logAction(`You aren't carrying a ${dropItem}.`);
        }
    }

    function logAction(action) {
        setLogText(logText => logText + `\n ${action}`);
    }

    function updateRoom(thing) {
        currentRoom.name = thing.name;
        currentRoom.description = thing.description;
        currentRoom.itemDescription = thing.itemDescription;
        currentRoom.items = thing.items;
        currentRoom.isDark = thing.isDark;
        currentRoom.lock = thing.lock;
        currentRoom.north = thing.north;
        currentRoom.south = thing.south;
        currentRoom.east = thing.east;
        currentRoom.west = thing.west;
    }
    

    function checkLight() {
        let worldLight = currentRoom.items.filter(item => item.illuminate === true);
        let inventoryLight = inventory.filter(item => item.illuminate === true);
        if (!currentRoom.isDark || worldLight.length > 0 || inventoryLight.length > 0) {
            return true;
        } else {
            return false;
        }
    }


    function handleUseItem(useItem) {
        let itemToUse = inventory.filter(item => item.name.toLowerCase() === useItem.toLowerCase());
        if (itemToUse.length === 0) {
            logAction(`You aren't carrying a ${useItem}.`);
        } else {
            itemToUse[0].use();
        }
    }

    function activateLantern() {
        let lantern = inventory.filter(item => item.name === "Lantern")[0];
        if (lantern.illuminate) {
            lantern.description = "A small, sturdy hooded iron lantern. 'N.H. Ironworks'. The hood is currently lowered.";
            logAction("You lower the hood of the lantern.");
        } else {
            lantern.description = "A small, sturdy hooded iron lantern. 'N.H. Ironworks'. The hood is currently raised.";
            logAction("You raise the hood of the lantern.");
        }
        lantern.illuminate = !lantern.illuminate;
    }

    function activateCoin(color) {
        if (currentRoom.name.toLowerCase() === "shrine") {
            let coin = inventory.filter(item => item.name.toLowerCase() === color.toLowerCase());
            let coinIndex = inventory.indexOf(coin[0]);
            inventory.splice(coinIndex, 1);
            logAction("You slide the coin into one of the slots in the shrine.");
            currentRoom.lock += 1;
            console.log(currentRoom.lock);
            if (currentRoom.lock === 3) {
                currentRoom.south = "Treasury";
                currentRoom.description = `A round stone room with an arched roof. 
                    Golden inlays run up the wall and meet in the center of the ceiling, forming a circular skylight. 
                    At the center of the room is a gilded, triangular shrine. On each side is a recessed circular slot. 
                    There is a large doorway to the south, revealing a room filled with gold.`;
                logAction("There is a great rumbling as the stone door at the south of the shrine crawls open.");
            }
        } else {
            logAction("Theres nothing for you to do with the coin here.")
        }
    }

    function activateParrot() {
        inventory.push({
            name:"Triangle", 
            description:"An intricately carved sapphire triangle 1 foot to each edge.",
            use:() => activateCoin("Triangle"),
        });
    }

    function activateBook() {
        let parrot = currentRoom.items.filter(item => item.name.toLowerCase() === "parrot");
        if (parrot.length > 0) {
            parrot[0].cantPickup = false;
            logAction("You sing a birdsong from the book. The parrot turns to look at you.");
        } else {
            logAction("You sing a birdsong from the book. There don't seem to be any birds around to hear it though.");
        }
    }

    function cantPickupParrot() {
        let parrot = currentRoom.items.filter(item => item.name.toLowerCase() === "parrot");
        if (currentRoom.name === "Courtyard") {
            let castle = rooms.filter(room => room.name === "Castle");
            castle[0].items.push(parrot[0]);
            logAction("The Parrot flies out of your reach in the direction of the Castle.");
        } else {
            let courtyard = rooms.filter(room => room.name === "Courtyard");
            courtyard[0].items.push(parrot[0]);
            logAction("The Parrot flies out of your reach in the direction of the Courtyard.");
        }
        let parrotIndex = currentRoom.items.indexOf(parrot[0]);
        currentRoom.items.splice(parrotIndex, 1);
    }

    return (
        <div className="adventureGame">
            <Console logText={logText} logAction={logAction} doAction={act}/>
            <div>
                <World room={currentRoom} handleMove={handleMove} lastMove={lastDir} checkLight={checkLight}/>
                <Inventory inventory={inventory}/>
            </div>
        </div>
    )
}