export default function World({room, handleMove, lastMove, checkLight}) {
    return (
        <div id="world">
            <div id = "location">
                <h1>{room.name}</h1>
                {!checkLight() ? <p>The room is too dark for you to see anything.</p> : <p>{room.description}</p>}
                {room.items.map(item => (
                    <div key={item.name}>{room.itemDescription} {item.name}</div>
                ))}
            </div>
            <div id = "Buttons">
                {(room.north !== "" && checkLight()) || (!checkLight() && lastMove === 'south')  ? 
                    <button id="northButton" onClick={() => {handleMove(room.north, 'north')}}>N</button> : 
                    <button id="northButton" disabled>N</button>
                }
                {(room.south !== "" && checkLight()) || (!checkLight() && lastMove === 'north') ? 
                    <button id="southButton" onClick={() => {handleMove(room.south, 'south')}}>S</button> : 
                    <button id="southButton" disabled>S</button>
                }
                {(room.east !== "" && checkLight()) || (!checkLight() && lastMove === 'west') ? 
                    <button id="eastButton" onClick={() => {handleMove(room.east, 'east')}}>E</button> : 
                    <button id="eastButton" disabled>E</button>
                }
                {(room.west !== "" && checkLight()) || (!checkLight() && lastMove === 'east') ? 
                    <button id="westButton" onClick={() => {handleMove(room.west, 'west')}}>W</button> : 
                    <button id="westButton" disabled>W</button>
                }
            </div>
        </div>
    )
}