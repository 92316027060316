import { useState } from "react";
import DiceColorSwitch from "./DiceColorSwitch";

export default function DiceColorPicker({selectedColor, handleSelectColor}) {
    const [colors, setColors] = useState(["red", "orange", "gold", "lime", "cyan", "violet", "#FFFFFF"]);
    let filter;

    function handleCustomColor(color) {
        let tempColors = colors.slice();
        tempColors[-1] = color;
        setColors(tempColors);
        handleSelectColor(color);
    }

    return (
        <div style={{float:"left", gridRow:"3", gridColumn:"1 / span 3"}}>
            {colors.map((color) => {
                {color === selectedColor ? filter = "" : filter = "opacity(0.5)"}
                return (
                    <DiceColorSwitch key={colors.indexOf(color)} color={color} handleSelectColor={handleSelectColor} filter={filter} handleCustomColor={handleCustomColor} />
                )
            })}
        </div>
    )
}