export default function ResumePage() {
    return (
        <>
            <div>
                <h1 className="resume-name"><a href='src/assets/Alex Bamberger-2024-December 2.docx' download>Alex Bamberger</a></h1>
                <h2 className="resume-name">Software Engineer</h2>
                <p className="resume-contact">alex@alexbamberger.com</p>
                <p className="resume-contact">(206) 512-0542</p>
                <p className="resume-contact"><a href="https://www.linkedin.com/in/alex-bamberger-80652a27a/">LinkedIn</a></p>

            </div>
            <div className="resume-left">
                <h2 className="resume-title">Objective</h2>
                <p className="resume-item">Seeking entry level position as a front end web developer allowing me to utilize my education while gaining work experience in a professional environment.</p>

                <h2 className="resume-title">Personal Website</h2>
                <h5 className="resume-subtitle">alexbamberger.com</h5>
                <h5 className="resume-subtitle">January 2023 – Present</h5>
                <p className="resume-item">Contains several sample projects such as: </p>
                <p className="resume-item" style={{marginLeft:"15%"}}> - Text based adventure game in an explorable and interactable world</p>
                <p className="resume-item" style={{marginLeft:"15%"}}> - Collection of complex animations customizable by the user</p>
                <p className="resume-item" style={{marginLeft:"15%"}}> - Random number generator allowing the user to choose and roll a number of dice</p>
                <p className="resume-item">Built in Javascript, remastered in React</p>

                <h2 className="resume-title">MePlan</h2>
                <h5 className="resume-subtitle">Task Tracking Website (Group Project)</h5>
                <h5 className="resume-subtitle">September 2023 – December 2023</h5>
                <p className="resume-item">Developed task tracking website for students using Javascript, HTML, CSS</p>
                <p className="resume-item">Co-led project, collected user requirements, developed design plan, implemented features, developed tests, and conducted testing</p>
                <p className="resume-item">Supported task prioritization and deadline creation</p>
                <p className="resume-item">Integrated with Google Calendar</p>

                <h2 className="resume-title">Math Tutoring</h2>
                <h5 className="resume-subtitle">Independent Math Tutor</h5>
                <h5 className="resume-subtitle">2021</h5>
                <p className="resume-item">Gave remote instruction on high school math subjects</p>
                <p className="resume-item">Assisted in tracking and completing assignments</p>
            </div>
            <div className="resume-right">
                <h2 className="resume-title">Education</h2>
                    <p className="resume-item">Bachelor of Science</p>
                    <p className="resume-item">Computer Science</p>
                    <p className="resume-item">Willamette University</p>

                <h2 className="resume-title">Skills</h2>
                    <p className="resume-item">Javascript (Intermediate)</p>
                    <p className="resume-item">React (Beginner)</p>
                    <p className="resume-item">HTML / CSS (Beginner)</p>
                    <p className="resume-item">Python (Beginner)</p>
                    <p className="resume-item">Git (Beginner)</p>
                    <p className="resume-item">AI Tools (Beginner)</p>

                <h2 className="resume-title">Relevant Courses</h2>
                    <p className="resume-item">Analysis of Algorithms</p>
                    <p className="resume-item">Software Development</p>
                    <p className="resume-item">Introduction to Machine Learning</p>
                    <p className="resume-item">Networks and Systems</p>
                    <p className="resume-item">Data Management with SQL</p>
                    <p className="resume-item">Software Development</p>
                    <p className="resume-item">Fundamentals of Data Science</p>
                    <p className="resume-item">Mobile App Development</p>
                    <p className="resume-item">Human-Computer Interaction</p>
            </div>
        </>
    )
}