import Die from "./Die"
import { motion } from "framer-motion"

export default function Roll({roll, isCurrent, diceSets, onSelectDiceSet}) {
    let opacity = isCurrent ? "" : "oldDice";

    const handleClick = () => {
        // Find the dice set in the existing sets
        const diceSet = diceSets.find(set => set.name === roll.diceSetName);
        
        if (diceSet) {
            // If found, select it
            onSelectDiceSet(diceSet);
        } else {
            // If not found, create a new dice set from this roll
            const newDiceSet = {
                name: roll.diceSetName,
                dice: roll.dice.map(die => ({
                    index: die.index,
                    color: die.color,
                    numSides: die.numSides,
                    value: die.numSides > 0 ? Math.floor(Math.random() * die.numSides) + 1 : 0,
                    modifier: die.modifier || 0
                })),
                index: diceSets.length
            };
            onSelectDiceSet(newDiceSet, true); // true indicates this is a new dice set
        }
    };

    return (
        <motion.div 
            className={`dice-display-window`}
            animate={isCurrent ? {opacity: [0, 1], y: [-90, 0], height: [0, 105], transition: {duration:0.5}} : ""}
        >
            <div className="dice-roll-info">
                <div 
                    className="dice-total" 
                    onClick={handleClick}
                >
                    <div className="dice-set-name">{roll.diceSetName}</div>
                    <div className="dice-total-title">{roll.total}</div>
                </div>
                {isCurrent && <div className="dice-total-subtitle">TOTAL</div>}
            </div>
            <div className={`all-dice ${opacity}`}>
                {roll.dice.map((die) => {
                    return(
                        <Die 
                            key={die.index} 
                            index={die.index} 
                            color={die.color} 
                            numSides={die.numSides} 
                            value={die.value} 
                            animate={die.numSides > 0} 
                            onClick={handleClick}
                        />
                    );
                })}
            </div>
        </motion.div>
    );
}