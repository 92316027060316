import { useRef, useEffect, useState } from "react";
import { pi, cos, sin, sqrt } from 'mathjs';

export default function SpinningBall() {
    const [frame, setFrame] = useState(1);
    const canvas = useRef();
    const [isPaused, setIsPaused] = useState(true);
    const [isReversed, setIsReversed] = useState(false);
    const [doLinger, setDoLinger] = useState(false);
    const [delay, setDelay] = useState(50);
    const [size, setSize] = useState(5);
    const [canvasWidth, setCanvasWidth] = useState(500);
    const [canvasHeight, setCanvasHeight] = useState(500);
    const [step, setStep] = useState(1);
    const [frameCount, setFrameCount] = useState(60);
    const [color, setColor] = useState('#000000');


    function handlePause() {setIsPaused(!isPaused);}
    function handleReverse() {setIsReversed(!isReversed);}
    function handleLinger() {setDoLinger(!doLinger);}
    const handleDelay = event => {setDelay(event.target.value);};
    const handleSize = event => {setSize(event.target.value);};
    const handleWidth = event => {setCanvasWidth(event.target.value);};
    const handleHeight = event => {setCanvasHeight(event.target.value);};
    const doSetStep = event => {setStep(event.target.value);};
    const handleFrames = event => {setFrameCount(event.target.value);};
    const handleColor = event => {setColor(event.target.value);};

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isPaused) {
                if (!isReversed) {
                    if (frame < 60) {
                        setFrame(frame + 1);
                    } else {
                        setFrame(1);
                    }
                } else {
                    if (frame > 1) {
                        setFrame(frame - 1);
                    } else {
                        setFrame(60);
                    }
                }
            }
        }, delay);
    
        return () => {
          clearInterval(interval);
        };
      });

    useEffect(() => {
        if (canvas.current) {
            const ctx = canvas.current.getContext('2d');
            let fraction = frame / frameCount;
            let r = (sqrt((canvasWidth**2) + (canvasHeight**2)) / 4);
            let x = (canvasWidth / 2) + (r * cos(pi * 2 * fraction));
            let y = (canvasHeight / 2) + (r * sin(pi * 2 * fraction));

            if (!doLinger) {
                ctx.clearRect(0, 0, canvasWidth, canvasHeight);
            }
            ctx.fillStyle = color;
            ctx.beginPath();
            ctx.ellipse(x, y, size, size, pi / 4, 0, 2 * pi);
            ctx.fill();
        }
    }, [frame, canvasHeight, canvasWidth, color, doLinger, frameCount, size]);

    function handleStep() {
        if (!isReversed) {
            if (frame < 60) {
                setFrame(Number(frame) + Number(step));
            } else {
                setFrame(1);
            }
        } else {
            if (frame > 1) {
                setFrame(Number(frame) - Number(step));
            } else {
                setFrame(60);
            }
        }
    }

    return (
        <>
            <div>
                <button onClick={handlePause}>{isPaused ? 'Play Animation' : 'Pause Animation'}</button>
                <button onClick={handleReverse}>{isReversed ? 'Playing Reversed' : 'Playing Forward'}</button>
                <button onClick={handleLinger}>{doLinger ? 'Frames Linger' : 'Frames Erased'}</button>
                <button onClick={handleStep}>Step Animation</button>
            </div>
            <div>
                <p className="animationRange">Color: {color}</p>
                <input onChange={handleColor} type='color' value={color} />
                <p className="animationRange">Dot Size: {size}</p>
                <input onChange={handleSize} type='range' min='1' max='20' step='1' value={size} />
            </div>
            <div>
                <p className="animationRange">Step: {step}</p>
                <input onChange={doSetStep} type='range' min='1' max='5' step='1' value={step} />
                <p className="animationRange">Delay: {delay}</p>
                <input onChange={handleDelay} type='range' min='1' max='60' step='1' value={delay} />
            </div>
            <div>
                <p className="animationRange">Canvas Height: {canvasHeight}</p>
                <input onChange={handleHeight} type='range' min='100' max='500' step='20' value={canvasHeight} />
                <p className="animationRange">Canvas Width: {canvasWidth}</p>
                <input onChange={handleWidth} type='range' min='100' max='500' step='20' value={canvasWidth} />
            </div>
            <div>
                <p className="animationRange">Total Frames: {frameCount}</p>
                <input onChange={handleFrames} type='range' min='10' max='200' step='1' value={frameCount} />
            </div>   
            <canvas
                width={canvasWidth}
                height={canvasHeight}
                className="canvas"
                ref={canvas}
            >
            </canvas>
            <p>Based on the tutorial by bleuje: https://bleuje.com/tutorial1/</p> 
        </>
    )
}