import { motion } from "framer-motion"


export default function Die({index, color, numSides, value, size, onClick, animate, className: propClassName}) {
    let className = "die-container";
    let dieClassName = "dice-" + numSides;
    if (size) {
        dieClassName += " small";
        className += "-small";
    }
    if (propClassName) {
        className += " " + propClassName;
    }

    let rotate = 0;
    let duration = 0;
    if (animate) {
        rotate = [0, 360 * (index + 1)];
        duration = 0.5 * (index + 1);
    }

    return (
        <motion.div className={className}
            animate={{rotate:rotate}}
            transition={{duration:duration}} 
            onClick={onClick}
        >
            <div className={dieClassName} style={{backgroundColor:color}}>{value}</div>
        </motion.div>
    );
}