import { motion } from "framer-motion";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function PageHeaderItem({text, link}) {
    return (
        <motion.div 
            className="page-header-item"
        >
            <h2 className="page-header-item-text">
                <Link to={link} className="page-header-item-link" >{text}</Link>
            </h2>
        </motion.div>
    )
}