import { useEffect, useState, useCallback } from "react";
import { AnimatePresence } from "framer-motion";
import Roll from "./Roll"
import DiceSelector from "./DiceSelector";
import DiceSetEditor from "./DiceSetEditor";
import DiceSetPreview from "./DiceSetPreview";

function makeDie(index, color, numSides, value, modifier) {
    return {
        index: index, 
        color: color, 
        numSides: numSides, 
        value: 0,
        modifier: modifier || 0,
    };
}

function makeRoll(dice, index, diceSetName) {
    return {
        index: index,
        dice: dice,
        total: 0,
        diceSetName: diceSetName
    }
}

function makeDiceSet(name, index, dice) {
    return {
        name: name, 
        index: index,
        dice: dice,
    }
}

let initialDiceSets = [
    makeDiceSet("Rainbow", 0, [
        makeDie(0, "red", 4),
        makeDie(1, "orange", 6),
        makeDie(2, "gold", 8),
        makeDie(3, "lime", 10),
        makeDie(4, "cyan", 12),
        makeDie(5, "violet", 20),
    ]),
    makeDiceSet("Fireball", 1, [
        makeDie(0, "red", 6),
        makeDie(1, "red", 6),
        makeDie(2, "gold", 6),
        makeDie(3, "gold", 6),
        makeDie(4, "white", 6),
        makeDie(5, "white", 6),
    ]),
    makeDiceSet("Chain Lightning", 2, [
        makeDie(0, "#FFD700", 8), // Yellow
        makeDie(1, "#FFE44D", 8), // Lighter yellow
        makeDie(2, "#FFF19A", 8), // Very light yellow
        makeDie(3, "#FFFFFF", 8), // White
        makeDie(4, "#E6F3FF", 8), // Very light blue
        makeDie(5, "#B3D9FF", 8), // Light blue
        makeDie(6, "#80C0FF", 8), // Medium light blue
        makeDie(7, "#4DA6FF", 8), // Medium blue
        makeDie(8, "#1A8CFF", 8), // Bright blue
        makeDie(9, "#0066CC", 8), // Deep blue
    ]),
];

let dieOptions = makeDiceSet("DieOptions", 0, [
        makeDie(0, "red", 4),
        makeDie(1, "red", 6),
        makeDie(2, "red", 8),
        makeDie(3, "red", 10),
        makeDie(4, "red", 12),
        makeDie(5, "red", 20)
])

export default function NewDice() {
    let [diceSets, setDiceSets] = useState(initialDiceSets);
    let [rollNumber, setRollNumber] = useState(0);
    let [mode, setMode] = useState("roll");
    let [selectedDiceSetIndex, setSelectedDiceSetIndex] = useState(0);
    let [rolls, setRolls] = useState([]);
    let [isRolling, setIsRolling] = useState(false);
    let [activeTimeouts, setActiveTimeouts] = useState([]);
    let [finishedDice, setFinishedDice] = useState(new Set());
    let [changeCounts, setChangeCounts] = useState({});
    let [shouldRollAfterSelection, setShouldRollAfterSelection] = useState(false);

    // Effect to handle rolling after dice set selection
    useEffect(() => {
        if (shouldRollAfterSelection) {
            handleClickDice();
            setShouldRollAfterSelection(false);
        }
    }, [selectedDiceSetIndex, shouldRollAfterSelection]);

    // Updates the value of a specific die while it's rolling
    const rollNewValues = useCallback((dieIndex) => {
        setRolls(prevRolls => {
            const newRolls = [...prevRolls];
            const activeRoll = { ...newRolls[0] };
            const newDice = [...activeRoll.dice];
            
            // Generate a new random value for the specified die
            const newValue = newDice[dieIndex].numSides > 0 ? 
                Math.floor(Math.random() * newDice[dieIndex].numSides) + 1 : 0;
            newDice[dieIndex] = {
                ...newDice[dieIndex],
                value: newValue
            };
            
            // Update the total and return the new state
            activeRoll.dice = newDice;
            activeRoll.total = newDice.reduce((sum, die) => sum + die.value + die.modifier, 0);
            newRolls[0] = activeRoll;
            return newRolls;
        });
    }, []);

    // Handles the rolling animation for a single die
    const rollDie = useCallback((dieIndex, startTime, duration) => {
        const rollInterval = 250; // How often the die changes value (in milliseconds)
        const numChanges = Math.floor(duration / rollInterval); // Calculate how many times this die should change value
        let changeCount = 0;

        // Function that handles a single roll cycle
        const roll = () => {
            if (changeCount >= numChanges) {
                // Set final value and mark as finished
                setRolls(prevRolls => {
                    const newRolls = [...prevRolls];
                    const activeRoll = { ...newRolls[0] };
                    const newDice = [...activeRoll.dice];
                    
                    // Generate the final random value
                    const finalValue = newDice[dieIndex].numSides > 0 ? 
                        Math.floor(Math.random() * newDice[dieIndex].numSides) + 1 : 0;
                    newDice[dieIndex] = {
                        ...newDice[dieIndex],
                        value: finalValue
                    };
                    
                    // Update the total and return the new state
                    activeRoll.dice = newDice;
                    activeRoll.total = newDice.reduce((sum, die) => sum + die.value + die.modifier, 0);
                    newRolls[0] = activeRoll;
                    return newRolls;
                });
                setFinishedDice(prev => new Set([...prev, dieIndex]));
                return;
            }

            // Update the die's value
            rollNewValues(dieIndex);
            changeCount++;

            // Schedule the next roll
            const timeoutId = setTimeout(roll, rollInterval);
            setActiveTimeouts(prev => [...prev, timeoutId]);
        };

        // Start the rolling animation
        roll();
    }, [rollNewValues]);

    // Effect that handles starting and managing the roll animation
    useEffect(() => {
        if (rollNumber > 0 && !isRolling) {
            // Reset the state for a new roll
            setFinishedDice(new Set());
            setIsRolling(true);

            const numDice = rolls[0].dice.length;
            const rollInterval = 250; // How often dice change value (in milliseconds)
            const baseDuration = 500; // Base duration for the first die (in milliseconds)
            const startTime = Date.now();

            // Start all dice rolling simultaneously
            // Each die rolls for 0.5 seconds longer than the previous one
            for (let i = 0; i < numDice; i++) {
                const duration = baseDuration * (i + 1); // First die: 0.5s, second: 1s, third: 1.5s, etc.
                rollDie(i, startTime, duration);
            }

            // Set up a final timeout to clean up after all dice have finished
            const totalDuration = baseDuration * numDice; // Last die stops after 0.5s * number of dice
            const finalTimeoutId = setTimeout(() => {
                // Clear all remaining timeouts
                activeTimeouts.forEach(id => clearTimeout(id));
                setActiveTimeouts([]);
                setIsRolling(false);
            }, totalDuration + 100); // Add a small buffer to ensure all dice have finished
            setActiveTimeouts(prev => [...prev, finalTimeoutId]);
        }

        // Cleanup function to clear timeouts when component unmounts or rollNumber changes
        return () => {
            activeTimeouts.forEach(id => clearTimeout(id));
            setActiveTimeouts([]);
            setIsRolling(false);
        };
    }, [rollNumber, rollDie]);

    function handleClickDice() {
        if (!isRolling) {
            const newRoll = makeRoll(
                diceSets[selectedDiceSetIndex].dice, 
                rollNumber + 1, 
                diceSets[selectedDiceSetIndex].name
            );
            setRolls(prevRolls => [newRoll, ...prevRolls]);
            setRollNumber(prev => prev + 1);
        }
    }

    function handleMode(mode) {
        setMode(mode);
    }

    function handleSelectDiceSet(diceSet, isNew = false) {
        if (isNew) {
            // Add the new dice set to the list
            setDiceSets(prev => {
                const newDiceSets = [...prev, diceSet];
                setSelectedDiceSetIndex(newDiceSets.length - 1);
                return newDiceSets;
            });
        } else {
            setSelectedDiceSetIndex(diceSet.index);
        }
        setMode("roll");
    }

    function handleEditDie(die, color, selectedDieIndex) {
        let newDiceSets = diceSets.slice();
        newDiceSets[selectedDiceSetIndex].dice[selectedDieIndex] = die;
        newDiceSets[selectedDiceSetIndex].dice[selectedDieIndex].color = color;
        newDiceSets[selectedDiceSetIndex].dice[selectedDieIndex].index = selectedDieIndex;
        setDiceSets(newDiceSets);
    }

    function handleAddDie(color, numSides) {
        let newDiceSets = diceSets.slice();
        let index = newDiceSets[selectedDiceSetIndex].dice.length;

        let newDie = makeDie(index, color, numSides);

        newDiceSets[selectedDiceSetIndex].dice.push(newDie);
        setDiceSets(newDiceSets);
    }

    function handleRemoveDie(selectedDieIndex) {
        let newDiceSets = diceSets.slice();
        newDiceSets[selectedDiceSetIndex].dice.splice(selectedDieIndex, 1);
        newDiceSets[selectedDiceSetIndex].dice.forEach((die) => {if (die.index > selectedDieIndex) {die.index -= 1}});
        setDiceSets(newDiceSets);
    }

    function handleRemoveDiceSet(diceSetIndex) {
        // If we're deleting the last dice set, don't allow it
        if (diceSets.length <= 1) {
            return;
        }
        
        // Create new dice sets array without the selected one
        const newDiceSets = diceSets.filter((_, index) => index !== diceSetIndex);
        
        // Update indices for remaining dice sets
        newDiceSets.forEach((diceSet, index) => {
            diceSet.index = index;
        });
            
        // If we're deleting the currently selected dice set, update the selection to 0
        if (diceSetIndex === selectedDiceSetIndex) {
            setSelectedDiceSetIndex(0);
        } else if (diceSetIndex < selectedDiceSetIndex) {
            // If we're deleting a dice set before the selected one, adjust the selected index
            setSelectedDiceSetIndex(selectedDiceSetIndex - 1);
        }
        
        // Update the dice sets and rolls
        setDiceSets(newDiceSets);
        setRolls([makeRoll(newDiceSets[selectedDiceSetIndex].dice, 0, newDiceSets[selectedDiceSetIndex].name)]);
    }

    function handleEditTitle(newTitle) {
        let newDiceSets = diceSets.slice();
        newDiceSets[selectedDiceSetIndex].name = newTitle;
    }

    function makeNewDiceSet() {
        const newDiceSet = makeDiceSet(`Dice Set ${diceSets.length}`, diceSets.length, [
            makeDie(0, "white", 4),
            makeDie(1, "white", 6),
            makeDie(2, "white", 8),
            makeDie(3, "white", 0, 3),
        ]);
        
        // Just add the new dice set without changing the selection
        setDiceSets(prevDiceSets => [...prevDiceSets, newDiceSet]);
    }

    function handleEditDiceSet(diceSet) {
        setSelectedDiceSetIndex(diceSet.index);
        setMode("edit");
    }

    // Add safety check for selected dice set
    const selectedDiceSet = diceSets[selectedDiceSetIndex] || diceSets[0];

    return (
        <div className="dice-project">
            <div className="header-container">
                <div className="dice-header">
                    {mode === "roll" && <button onClick={handleClickDice} className="roll-button">Roll</button>}
                </div>
                {mode === "roll" && (
                    <DiceSetPreview 
                        diceSet={diceSets[selectedDiceSetIndex]} 
                        onChangeSet={() => handleMode("swap")}
                    />
                )}
            </div>

            <div className="dice-display-container">
                {rolls.map((roll) => {
                    let isCurrent = roll.index === rolls[0].index;
                    return (
                        <Roll 
                            key={roll.index} 
                            roll={roll} 
                            doRoll={handleClickDice} 
                            isCurrent={isCurrent}
                            diceSets={diceSets}
                            onSelectDiceSet={handleSelectDiceSet}
                        />
                    );
                })}
            </div>

            <AnimatePresence>
                {mode === "swap" && 
                    <DiceSelector 
                        key="dice-selector"
                        diceSets={diceSets} 
                        selectedDiceSet={diceSets[selectedDiceSetIndex]} 
                        handleSelectDiceSet={handleSelectDiceSet} 
                        handleRemoveDiceSet={handleRemoveDiceSet}
                        makeNewDiceSet={makeNewDiceSet}
                        handleEditDiceSet={handleEditDiceSet}
                    />
                }
            </AnimatePresence>

            <AnimatePresence>
                {mode === "edit" && 
                    <DiceSetEditor 
                        key="dice-set-editor"
                        selectedDiceSet={selectedDiceSet} 
                        dieOptions={dieOptions} 
                        handleEditDie={handleEditDie}
                        handleAddDie={handleAddDie}
                        handleRemoveDie={handleRemoveDie}
                        handleEditTitle={handleEditTitle}
                        onClose={() => handleMode("swap")}
                    />
                }
            </AnimatePresence>
        </div>
    )
}