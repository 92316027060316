import { useRef } from "react"

export default function Console({logText, logAction, doAction}) {
    const inputRef = useRef();

    function parseInput() {
        let input = inputRef.current.value;
        logAction(input);
      
        let north = ['n', 'north', 'go north', 'move north'];
        let south = ['s', 'south', 'go south', 'move south'];
        let east = ['e', 'east', 'go east', 'move east'];
        let west = ['w', 'west', 'go west', 'move west'];
        let start = ['start', 'begin', 'start game'];
      
        let pickup = ['pickup', 'take', 'grab'];
        let drop = ['drop', 'leave', 'place'];
        let use = ['use', 'activate'];
        let cutInput = input.split(' ');
      
        switch (input) {
            case (start.includes(input.toLowerCase()) ? input : null):
                doAction('start', '');
                break;
            case (north.includes(input.toLowerCase()) ? input : null):
                doAction('move', 'north');
                break;
            case (south.includes(input.toLowerCase()) ? input : null):
                doAction('move', 'south');
                break;
            case (east.includes(input.toLowerCase()) ? input : null):
                doAction('move', 'east');
                break;
            case (west.includes(input.toLowerCase()) ? input : null):
                doAction('move', 'west');
                break;
            case (pickup.includes(cutInput[0].toLowerCase()) ? input : null):
                doAction('pickup', cutInput[1].toLowerCase());
                break;
            case (drop.includes(cutInput[0].toLowerCase()) ? input : null):
                doAction('drop', cutInput[1].toLowerCase());
                break;
            case (use.includes(cutInput[0].toLowerCase()) ? input : null):
                doAction('use', cutInput[1].toLowerCase());
                break;
          default:
            logAction(`${input} isn't a recognized action.`);
        }
        inputRef.current.value = '';
    }

    return (
        <div id="console">
            <h1>Console</h1>
            <div id="consoleLog">{logText}</div>
            <input type="text" id="consolePrompt" ref={inputRef} placeholder="What do you want to do?"/>
            <button onClick={parseInput}>Act</button>
        </div>
    )
}