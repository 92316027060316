import ExpandableButton from '../ExpandableButton';


export default function AboutPage() {

  return (
    <div className='about-page'>
      <div className='about-page-items' >
        <h3 className='about-title'>About Me</h3>
        <p className='about-text'>
          Between Fall 2019 and Spring 2024 I studied computer science at Willamette University, graduating with a Bachlor's Degree in Computer Science. 
          During my time at Willamette I developed a strong foundation in the principles thereof with a particular focus on front end development. 
          I am proficient with Javascript, HTML, CSS, and React. I also have experience with Python, SQL, and Git.
          Although my primary focus thus far has been on front end development I have experience with full stack development as well. 
          I am seeking an entry level position that would allow me to use my skills and education while simultaneously allowing me to develop professional work experience.
        </p>
        <h3 className='about-title'>Contact</h3>
        <p className='about-text'>Email: alex@alexbamberger.com</p>
        <p className='about-text'>Phone: (206) 512-0542</p>
      </div>
    </div>
  )
}