import { motion } from "framer-motion"
import Die from "./Die"
import { useRef, useState } from "react";
import DieEditor from "./DieEditor";

export default function DiceSetEditor({ selectedDiceSet, dieOptions, handleEditDie, handleAddDie, handleRemoveDie, handleEditTitle, onClose }) {
    let [selectedDieIndex, setSelectedDieIndex] = useState();
    let [mode, setMode] = useState("");
    let [title, setTitle] = useState("Add a Die");
    let titleRef = useRef();

    function handleSelectDie(index) {
        if (index === selectedDieIndex) {
            setSelectedDieIndex();
            setMode("");
            setTitle("Add a Die");
        } else {
            setSelectedDieIndex(index);
            setMode("edit");
            setTitle("Edit die");
        }
    }

    function handleDeleteDie() {
        setSelectedDieIndex();
        handleRemoveDie(selectedDieIndex);
        setMode("");
    }

    function handleChange() {
        handleEditTitle(titleRef.current.value);
    }

    function handleCreateDie(die, color) {
        handleAddDie(color, die.numSides);
    }

    return (
        <motion.div 
            className="dice-window"
            initial={{ opacity: 0, x: 300 }}
            animate={{ opacity: 1, x: 0, delay: 0.25 }}
            exit={{ opacity: 0, x: 300, delay: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="dice-set-editor">
                <div className="dice-set-editor-left">
                    <div className="dice-set-editor-header">
                        <button 
                            className="dice-action-button back"
                            onClick={onClose}
                        >←
                        </button>
                        <input 
                            className="dice-set-editor-title"
                            defaultValue={selectedDiceSet.name} 
                            ref={titleRef} 
                            onChange={handleChange}
                            placeholder="Enter dice set name"
                        />
                    </div>
                    <div className="dice-set-editor-dice">
                        {selectedDiceSet.dice.map((die) => (
                            <Die 
                                key={die.index} 
                                index={die.index} 
                                color={die.color} 
                                numSides={die.numSides} 
                                value={die.index}
                                onClick={() => handleSelectDie(die.index)}
                                className={die.index === selectedDieIndex ? 'editing' : ''}
                            />
                        ))}
                    </div>
                    <div className="dice-set-editor-buttons">
                        {mode === "" && (
                            <button 
                                className="dice-set-editor-add-button"
                                onClick={() => setMode("add")}
                            >
                                Add a Die
                            </button>
                        )}
                    </div>
                </div>
                <div className="dice-set-editor-right">
                    {mode === "edit" && (
                        <DieEditor 
                            dieOptions={dieOptions} 
                            handleEditDie={handleEditDie} 
                            handleAddDie={handleAddDie}
                            handleRemoveDie={handleDeleteDie}
                            selectedDieIndex={selectedDieIndex} 
                            selectedDiceSet={selectedDiceSet}
                            title={title} 
                            onClose={() => {
                                setSelectedDieIndex();
                                setMode("");
                                setTitle("Add a Die");
                            }}
                        />
                    )}
                    {mode === "add" && (
                        <DieEditor 
                            dieOptions={dieOptions} 
                            handleEditDie={handleCreateDie} 
                            handleRemoveDie={handleDeleteDie}
                            selectedDiceSet={selectedDiceSet}
                            title={title} 
                            onClose={() => {
                                setMode("");
                                setTitle("Add a Die");
                            }}
                        />
                    )}
                </div>
            </div>
        </motion.div>
    )
}