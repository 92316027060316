import { useRef } from "react";

export default function DiceColorSwitch({color, handleSelectColor, filter, handleCustomColor}) {
    let inputRef = useRef();
    let colorName = color;
    let background;

    if (color[0] === "#" || color === "white") {
        colorName = "custom";
        background = "linear-gradient(in hsl longer hue 45deg, red 0 0)";
    }

    function handleChange() {
        handleCustomColor(inputRef.current.value);
    }

    return (
        <div className="dice-color-switch" style={{ backgroundColor: `${color}`, filter:filter, background:`${background}` }} onClick={() => handleSelectColor(color)}>
            {colorName === 'custom' && <input type="color" className="dice-color-input" ref={inputRef} onChange={handleChange} defaultValue={"#ffffff"} />}
        </div>
    )
}